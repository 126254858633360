<template>
  <b-card @click="openUrl" class="notification">
    <b-card-text class="d-flex align-items-center gap-1_2">
      <b-avatar
        :variant="data.is_read == 0? 'secondary':'light'"
        :icon="icon"
        :badge="data.is_read == 0"
      />
      <div>
        <strong class="d-block">{{ data.title }}</strong>
        <span class="text-muted">{{ data.desc }}</span><br />
        <small :class="data.is_read == 0? 'text-primary':'text-muted'">{{ data.datetime  | moment("from", "now")  }}</small>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import axios from 'axios'
import {
    mapGetters
} from 'vuex'

export default {
  props: ['data'],
  data () {
    return {
      icon: 'b-icon-bell'
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'GET_USER_INFO',
    })
  },
  mounted () {
    // patch icon by type of notification
    if(this.data.type == 1){
      this.icon = 'b-icon-chat'
    }
    if(this.data.type == 2){
      this.icon = 'b-icon-coin'
    }
  },
  methods: {
    openUrl() {
      // mark notification is_read status to 1
      try {
        const formData = new FormData();
        formData.append('auth_memberid', this.userInfo.auth_memberid);
        formData.append('auth_reference', this.userInfo.auth_token);

        // mark as readed
        console.log(this.data.hash)
        axios.post(`https://api2.novelrealm.com/module/notification&submodule=read&hash=${this.data.hash}`, formData)
        // open url in new tab
        window.open(this.data.url, '_blank')
      } catch (err) {
        //console.log('FAILURE!!' + err)
        console.log(err)
      }
    }
  }
}
</script>

<style scoped>
.notification{
  cursor: pointer;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.notification:hover{
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.gap-1_2 {
  gap: .5rem;
}
</style>
<template>
    <b-container id="mode">
        <div class="bg-white">
            <h3>การแจ้งเตือน</h3>
            <hr />
            <b-nav class="mb-2" pills justified>
                <b-nav-item :active="notificationMode == 'all'" @click="setNotificationMode('all')">ทั้งหมด</b-nav-item>
                <b-nav-item :active="notificationMode == 'unread'" @click="setNotificationMode('unread')">ยังไม่ได้อ่าน</b-nav-item>
            </b-nav>
            <div v-if="notifications.length != 0" class="d-flex flex-column gap-2">
                <NotificationCard v-for="notification in notifications" :key="notification.hash" :data="notification" />
            </div>
            <div v-else>
                <b-card class="text-center">
                    <h5 class="text-muted mb-0">ไม่พบการแจ้งเตือนใด ๆ</h5>
                </b-card>
            </div>
        </div>
    </b-container>
</template>

<script>
import axios from 'axios'
import router from '../../router/index'
import {
    mapGetters,
    mapActions
} from 'vuex'
import NotificationCard from '../elements/NotificationCard.vue';
export default {
    data () {
        return {
            notifications: [],
            notificationMode: 'all',
            userInfo: JSON.parse(localStorage.getItem('UserInfo'))
        }
    },
    watch: {
        mode() {
            this.checkMode();
        }
    },
    computed: {
        ...mapGetters({
            mode: "GET_MODE"
        })
    },
    mounted: function () {
        this.checkMode();
        this.fetchNotification()
    },
    methods: {
        ...mapActions(["SET_KEYWORD", "SET_SEARCH_NOVEL_WORD", "SET_LOADING_STATUS", "SET_MODE"]),
        async fetchNotification(mode = 'all'){
            try{
                const formData = new FormData();
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
                const response = await axios.post('https://api2.novelrealm.com/module/notification&submodule=get_all&filter='+mode, formData)

                if(response.data.status == "200"){
                    this.notifications = response.data.notifications.notifications
                }
                else{
                    router.push('/')
                }
            }
            catch(e){
                router.push('/')
            }
            // this.linkToFb = data.redirect_url
        },
        setNotificationMode (mode) {
            this.notificationMode = mode
            this.fetchNotification(mode)
        },
        checkMode() {
            if (this.mode == "darkmode") {
                this.darkmode();
            }
            else {
                this.lightmode();
            }
        },
        lightmode() {
            if (document.getElementById("mode").classList.contains("darkmode")) {
                document.getElementById("mode").classList.remove("darkmode");
            }
        },
        darkmode() {
            if (!document.getElementById("mode").classList.contains("darkmode")) {
                document.getElementById("mode").classList.add("darkmode");
            }
        }
    },
    components: { NotificationCard }
};
</script>

<style scoped>
#mode{
    padding: 2% 2% 2% 2%;
}
.darkmode h1{
    color: #fff
}
.darkmode hr{
    border-color: #fff;
}
.bg-white{
    background: #fff;
    padding: 2% 2% 2% 2%;
}
.gap-2{
    gap: 1rem;
}
</style>